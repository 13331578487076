// —————————————————————————————————————————————————————————————————————————————————————————
// [START] Imports —————————————————————————————————————————————————————————————————————————
// —————————————————————————————————————————————————————————————————————————————————————————

// CSS
import '../css/main.scss';

// jQuery
import $ from "jquery";

// Locomotive scroll
import LocomotiveScroll from 'locomotive-scroll';

// Barba.js
import barba from '@barba/core';
import barbaCss from '@barba/css';
barba.use(barbaCss);

// Gsap
import gsap from "gsap";

// Swiper
import Swiper from 'swiper';
import '../../../node_modules/swiper/css/swiper.min.css';

// ———————————————————————————————————————————————————————————————————————————————————————
// [END] Imports —————————————————————————————————————————————————————————————————————————
// ———————————————————————————————————————————————————————————————————————————————————————

// [START] Preloader ————————
$(window).on('load', function () {

  var preloaderTl = gsap.timeline();

  var heroRevealTl = gsap.timeline({
    paused: true
  });

  function startHeroTl() {
    heroRevealTl.play();
  }

  preloaderTl
  .set('.c-img__wrap', {
    pointerEvents: 'none'
  })
    .set('.c-h2__wrap', {
      autoAlpha: 0,
    })
    .fromTo(".o-preloader svg", {
      autoAlpha: 0,
      scale: 0.8,
      duration: 1
    }, {
      autoAlpha: 1,
      scale: 1,
      duration: 0.64,
      delay: 0.5
    })
    .to(".o-preloaderp-images_wrap", {
      xPercent: -100,
      duration: 8.84,
      ease: "power1.inOut"
    })
    .to(".o-preloader", {
      yPercent: -120,
      duration: 0.96,
      delay: 0.32
    })
    .set(".o-preloader", {
      display: 'none'
    })
    .set('.c-img__wrap', {
      pointerEvents: 'all'
    })
    ;

  preloaderTl.eventCallback("onComplete", startHeroTl);

  // [END] Preloader ————————


  // [START] Hero reveal Animation ————————

  if ($('.o-section-hero').length > 0) {

    heroRevealTl
      .set('.c-img__wrap', {
        pointerEvents: 'none'
      })
      .set('.o-section-hero .c-title__wrap .c-h2__wrap', {
        autoAlpha: 1,
        top: '50%',
        right: '50%',
        x: '50%',
        y: '-50%',
        rotateX: 90,
        fontSize: '10vw',
      })
      .to('.o-section-hero .c-title__wrap .c-h2__wrap', {
        rotateX: 0,
        duration: 0.64,
        ease: "expoScale(0.5, 3, 'power2.out')"
      })
      .to('.o-section-hero .c-title__wrap .c-h2__wrap', {
        fontSize: '3.25vw',
        top: '-50%',
        y: '0%',
        ease: "expoScale(0.5, 3, 'power2.out')"
      })
      .to('.o-section-hero .c-title__wrap .c-h2__wrap', {
        right: 0,
        x: '0%',
        ease: "expoScale(0.5, 3, 'power2.out')"
      })
      .fromTo('.o-section-hero .c-title__wrap .c-h1__wrap .c-h1', {
        rotateX: 90,
      }, {
        rotateX: 0,
        duration: 0.64,
        stagger: 0.32,
        ease: "expoScale(0.5, 3, 'power2.out')"
      })
      .fromTo('.o-section-hero .c-img__wrap .c-bg-img-back, .o-section-hero .c-img__wrap .c-bg-img-front, .c-hero-gradient', {
        rotateX: -90,
        transformOrigin: 'top',
      }, {
        rotateX: 0,
        transformOrigin: 'top',
        duration: 1.28,
        stagger: -0.32,
        ease: "expoScale(0.5, 3, 'power2.out')"
      }, '-=0.36')
      .fromTo('.o-hero-landing-btn', {
        autoAlpha: 0,
        scale: 0.5
      }, {
        autoAlpha: 1,
        duration: 0.56,
        scale: 1,
        ease: "expoScale(0.5, 3, 'power2.out')"
      }, '-=0.36')
      .set('.c-img__wrap', {
        pointerEvents: 'all'
      });


  }

});

// [END] Hero reveal Animation ————————


// Baba Init ————————
barba.init();

//Barba Global hooks ————————
barba.hooks.afterOnce((data) => {

  var mySwiper = new Swiper('.swiper-container', {
    // Optional parameters
    direction: 'horizontal',
    grabCursor: true,
    loop: true,
    breakpoints: {
      1000: {
        slidesPerView: 3,
      },
      650: {
        slidesPerView: 2,
      }
    },

    // Navigation arrows
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
  });

  // Slider 2 ————————

  var mySwiper2 = new Swiper('.swiper-container2', {
    // Optional parameters
    direction: 'horizontal',
    grabCursor: true,
    mousewheel: true,
    speed: 1750,

    // Navigation arrows
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    }
  });

  // Slider 3 ————————

  var mySwiper2 = new Swiper('.swiper-container3', {
    // Optional parameters
    grabCursor: true,
    loop: true,
    speed: 1750,

    // Navigation arrows
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    }
  });

  // ————————

  $(function () {
    var Accordion = function (el, multiple) {
      this.el = el || {};
      this.multiple = multiple || false;

      var links = this.el.find('.article-title');
      links.on('click', {
        el: this.el,
        multiple: this.multiple
      }, this.dropdown);
    };

    Accordion.prototype.dropdown = function (e) {
      var $el = e.data.el,
        $this = $(this),
        $next = $this.next();

      $next.slideToggle();
      $this.parent().toggleClass('open');

      if (!e.data.multiple) {
        $el.find('.accordion-content').not($next).slideUp().parent().removeClass('open');
      };
    };
    var accordion = new Accordion($('.accordion-container'), false);
  });

  $('.c-tab-item-02').click(function () {
    $('.c-tab-item-01').removeClass('c-tab-active');
    $('.c-tab-item-02').addClass('c-tab-active');
    $('.c-tab-content-01').removeClass('c-tab-content-active');
    $('.c-tab-content-02').addClass('c-tab-content-active');
  
  });
  
  $('.c-tab-item-01').click(function () {
    $('.c-tab-item-02').removeClass('c-tab-active');
    $('.c-tab-item-01').addClass('c-tab-active');
    $('.c-tab-content-02').removeClass('c-tab-content-active');
    $('.c-tab-content-01').addClass('c-tab-content-active');
  
  });

  setTimeout(
    function () {
      var scroll = new LocomotiveScroll({
        el: document.querySelector('#js-scroll'),
        smooth: true
      });

      $('.o-hero-landing-btn').click(function () {
        scroll.scrollTo('#act-for-culture', -80);
      });

      // Hide scroll bar if at top or bottom ————————
      scroll.on('scroll', function () {

        if ((!$('.content-entry').hasClass('is-inview')) && ($('.content-entry').hasClass('open'))) {
          $('.accordion-content').slideUp();
          $('.content-entry').removeClass('open');
        }

        if ($(".c-scrollbar").length) {

          var scrollBarPos = $('.c-scrollbar_thumb').position();
          var scrollBarHeight = $('.c-scrollbar').height() - $('.c-scrollbar_thumb').height();

          if (scrollBarPos.top == 0) {
            $('.c-scrollbar_thumb').addClass('is-top');
          } else {
            $('.c-scrollbar_thumb').removeClass('is-top');
          }

          if (scrollBarPos.top >= scrollBarHeight - 0.05) {
            $('.c-scrollbar_thumb').addClass('is-bottom');
          } else {
            $('.c-scrollbar_thumb').removeClass('is-bottom');
          }


          setTimeout(
            function () {

            },
            1500);

          if (scrollBarPos.top > 10) {
            $('.c-header').addClass('resize');
          } else {
            $('.c-header').removeClass('resize');
          }

        }

      });



      scroll.on('call', (value) => {

        if (value == 'startCounting') {

          $('.counting').each(function () {
            var $this = $(this),
              countTo = $this.attr('data-count');

            $({
              countNum: $this.text()
            }).animate({
                countNum: countTo
              },

              {

                duration: 3000,
                easing: 'linear',
                step: function () {
                  $this.text(Math.floor(this.countNum).toLocaleString('fr'));
                },
                complete: function () {
                  $this.text((this.countNum).toLocaleString('fr'));
                  //alert('finished');
                }

              });

          });
        }

        if (value == 'startCounting2') {

          $('.counting2').each(function () {
            var $this = $(this),
              countTo = $this.attr('data-count');

            $({
              countNum: $this.text()
            }).animate({
                countNum: countTo
              },

              {

                duration: 3000,
                easing: 'linear',
                step: function () {
                  $this.text(Math.floor(this.countNum).toLocaleString('fr'));
                },
                complete: function () {
                  $this.text((this.countNum).toLocaleString('fr'));
                  //alert('finished');
                }

              });

          });
        }

        if (value == 'startCounting3') {

          $('.counting3').each(function () {
            var $this = $(this),
              countTo = $this.attr('data-count');

            $({
              countNum: $this.text()
            }).animate({
                countNum: countTo
              },

              {

                duration: 3000,
                easing: 'linear',
                step: function () {
                  $this.text(Math.floor(this.countNum).toLocaleString('fr'));
                },
                complete: function () {
                  $this.text((this.countNum).toLocaleString('fr'));
                  //alert('finished');
                }

              });

          });
        }

        if (value == 'startCounting4') {

          $('.counting4').each(function () {
            var $this = $(this),
              countTo = $this.attr('data-count');

            $({
              countNum: $this.text()
            }).animate({
                countNum: countTo
              },

              {

                duration: 3000,
                easing: 'linear',
                step: function () {
                  $this.text(Math.floor(this.countNum).toLocaleString('fr'));
                },
                complete: function () {
                  $this.text((this.countNum).toLocaleString('fr'));
                  //alert('finished');
                }

              });

          });
        }

        if (value == 'startCounting5') {

          $('.counting5').each(function () {
            var $this = $(this),
              countTo = $this.attr('data-count');

            $({
              countNum: $this.text()
            }).animate({
                countNum: countTo
              },

              {

                duration: 3000,
                easing: 'linear',
                step: function () {
                  $this.text(Math.floor(this.countNum).toLocaleString('fr'));
                },
                complete: function () {
                  $this.text((this.countNum).toLocaleString('fr'));
                  //alert('finished');
                }

              });

          });
        }

        if (value == 'startCounting6') {

          $('.counting6').each(function () {
            var $this = $(this),
              countTo = $this.attr('data-count');

            $({
              countNum: $this.text()
            }).animate({
                countNum: countTo
              },

              {

                duration: 3000,
                easing: 'linear',
                step: function () {
                  $this.text(Math.floor(this.countNum).toLocaleString('fr'));
                },
                complete: function () {
                  $this.text((this.countNum).toLocaleString('fr'));
                  //alert('finished');
                }

              });

          });
        }

        if (value == 'startCounting7') {

          $('.counting7').each(function () {
            var $this = $(this),
              countTo = $this.attr('data-count');

            $({
              countNum: $this.text()
            }).animate({
                countNum: countTo
              },

              {

                duration: 3000,
                easing: 'linear',
                step: function () {
                  $this.text(Math.floor(this.countNum).toLocaleString('fr'));
                },
                complete: function () {
                  $this.text((this.countNum).toLocaleString('fr'));
                  //alert('finished');
                }

              });

          });
        }

        if (value == 'startCounting8') {

          $('.counting8').each(function () {
            var $this = $(this),
              countTo = $this.attr('data-count');

            $({
              countNum: $this.text()
            }).animate({
                countNum: countTo
              },

              {

                duration: 3000,
                easing: 'linear',
                step: function () {
                  $this.text(Math.floor(this.countNum).toLocaleString('fr'));
                },
                complete: function () {
                  $this.text((this.countNum).toLocaleString('fr'));
                  //alert('finished');
                }

              });

          });
        }


        // ————————
      });

      $('.swiper-container2').mouseenter(function () {
        if ($(window).width() > 1024) {
          scroll.stop();
          scroll.scrollTo('#slider', -80);
        }
      });

      $('.swiper-container2').mouseleave(function () {
        scroll.start();
      });

      mySwiper.on('reachEnd', function () {
        setTimeout(
          function () {
            scroll.start();
          }, 320);

      });

      mySwiper.on('reachBeginning', function () {
        setTimeout(
          function () {
            scroll.start();
          }, 320);

      });

      mySwiper2.on('reachEnd', function () {
        setTimeout(
          function () {
            scroll.start();
          }, 320);

      });

      mySwiper2.on('reachBeginning', function () {
        setTimeout(
          function () {
            scroll.start();
          }, 320);

      });

    });

}, 1000);

barba.hooks.beforeLeave((data) => {
  $('html').addClass('is-loading');
  $(".c-scrollbar").detach();
  $('html').removeClass('has-scroll-scrolling');
});

// ———————————————————————————————————————————————————————————————————————————————————————————————————
// [START] Barba After Enter —————————————————————————————————————————————————————————————————————————
// ———————————————————————————————————————————————————————————————————————————————————————————————————

barba.hooks.afterEnter((data) => {
  $('html').removeClass('is-loading');
  $('.c-scrollbar_thumb').addClass('is-top');

  // ————————

  // Images Swap Animation ————————

  if ($('.c-img__wrap').length > 0) {

    var imagesSwapTl = gsap.timeline({
      paused: true
    });

    imagesSwapTl
      .to('.c-bg-img-front', {
        autoAlpha: 0,
        top: '-4vw',
        bottom: '4vw',
        right: '-4vw',
        left: '4vw',
        duration: 0.64,
        ease: "expoScale(0.5, 3, 'power2.inOut')"
      })
      .to('.c-bg-img-back', {
        autoAlpha: 0,
        top: '4vw',
        bottom: '-4vw',
        right: '4vw',
        left: '-4vw',
        duration: 0.64,
        ease: "expoScale(0.5, 3, 'power2.inOut')"
      }, 0)
      .to('.c-bg-img-back-hidden', {
        autoAlpha: 1,
        top: '-4vw',
        bottom: '4vw',
        right: '-4vw',
        left: '4vw',
        duration: 0.64,
        ease: "expoScale(0.5, 3, 'power2.inOut')"
      }, 0)
      .to('.c-bg-img-front-hidden', {
        autoAlpha: 1,
        top: '4vw',
        bottom: '-4vw',
        right: '4vw',
        left: '-4vw',
        duration: 0.64,
        ease: "expoScale(0.5, 3, 'power2.inOut')"
      }, 0);

    $('.c-img__wrap, .o-hero-landing-btn').mouseenter(function () {
      imagesSwapTl.play();
    });

    $('.c-img__wrap, .o-hero-landing-btn').mouseleave(function () {
      imagesSwapTl.reverse();
    });

  }

  // ————————

  // Scroll Button Animation  ————————

  if ($('.o-hero-landing-btn').length > 0) {
    var scrollButtonTl = gsap.timeline({
      repeat: -1,
      repeatDelay: 0.96
    });
    scrollButtonTl
      .to('.o-hero-landing-btn svg', {
        y: '0%',
        duration: 0.56,
        ease: "expoScale(0.5, 3, 'power2.out')"
      })
      .to('.o-hero-landing-btn svg', {
        y: '-50%',
        duration: 0.72,
        ease: "expoScale(0.5, 3, 'power2.in')"
      });
  }

  // ————————

  // Band Horizontal Scroll Animation  ————————

  if ($('.o-band-values').length > 0) {
    var bandTl = gsap.timeline({
      repeat: -1
    });
    bandTl
      .fromTo('.o-band-values p', {
        x: '0%'
      }, {
        x: '-100%',
        duration: 16.4,
        ease: "none"
      });
  }

  // ————————

});

// —————————————————————————————————————————————————————————————————————————————————————————————————
// [END] Barba After Enter —————————————————————————————————————————————————————————————————————————
// —————————————————————————————————————————————————————————————————————————————————————————————————

barba.hooks.leave((data) => {
  scroll.stop();
});

barba.hooks.enter((data) => {
  var mySwiper = new Swiper('.swiper-container', {
    // Optional parameters
    direction: 'horizontal',
    grabCursor: true,
    loop: true,
    breakpoints: {
      1000: {
        slidesPerView: 3,
      },
      650: {
        slidesPerView: 2,
      }
    },

    // Navigation arrows
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
  });

  // Slider 2 ————————

  var mySwiper2 = new Swiper('.swiper-container2', {
    // Optional parameters
    direction: 'horizontal',
    grabCursor: true,
    mousewheel: true,
    speed: 1750,

    // Navigation arrows
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    }
  });

  // Slider 3 ————————

  var mySwiper3 = new Swiper('.swiper-container3', {
    // Optional parameters
    grabCursor: true,
    loop: true,
    speed: 1750,

    // Navigation arrows
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    }
  });

  // ————————

  $(function () {
    var Accordion = function (el, multiple) {
      this.el = el || {};
      this.multiple = multiple || false;

      var links = this.el.find('.article-title');
      links.on('click', {
        el: this.el,
        multiple: this.multiple
      }, this.dropdown);
    };

    Accordion.prototype.dropdown = function (e) {
      var $el = e.data.el,
        $this = $(this),
        $next = $this.next();

      $next.slideToggle();
      $this.parent().toggleClass('open');

      if (!e.data.multiple) {
        $el.find('.accordion-content').not($next).slideUp().parent().removeClass('open');
      };
    };
    var accordion = new Accordion($('.accordion-container'), false);
  });

  $('.c-tab-item-02').click(function () {
    $('.c-tab-item-01').removeClass('c-tab-active');
    $('.c-tab-item-02').addClass('c-tab-active');
    $('.c-tab-content-01').removeClass('c-tab-content-active');
    $('.c-tab-content-02').addClass('c-tab-content-active');
  
  });
  
  $('.c-tab-item-01').click(function () {
    $('.c-tab-item-02').removeClass('c-tab-active');
    $('.c-tab-item-01').addClass('c-tab-active');
    $('.c-tab-content-02').removeClass('c-tab-content-active');
    $('.c-tab-content-01').addClass('c-tab-content-active');
  
  });

  setTimeout(
    function () {
      var scroll = new LocomotiveScroll({
        el: document.querySelector('#js-scroll'),
        smooth: true
      });

      $('.o-hero-landing-btn').click(function () {
        scroll.scrollTo('#act-for-culture', -80);
      });

      // Hide scroll bar if at top or bottom ————————
      scroll.on('scroll', function () {

        if ((!$('.content-entry').hasClass('is-inview')) && ($('.content-entry').hasClass('open'))) {
          $('.accordion-content').slideUp();
          $('.content-entry').removeClass('open');
        }

        if ($(".c-scrollbar").length) {

          var scrollBarPos = $('.c-scrollbar_thumb').position();
          var scrollBarHeight = $('.c-scrollbar').height() - $('.c-scrollbar_thumb').height();

          if (scrollBarPos.top == 0) {
            $('.c-scrollbar_thumb').addClass('is-top');
          } else {
            $('.c-scrollbar_thumb').removeClass('is-top');
          }

          if (scrollBarPos.top >= scrollBarHeight - 0.05) {
            $('.c-scrollbar_thumb').addClass('is-bottom');
          } else {
            $('.c-scrollbar_thumb').removeClass('is-bottom');
          }

          if (scrollBarPos.top > 10) {
            $('.c-header').addClass('resize');
          } else {
            $('.c-header').removeClass('resize');
          }

        }

      });



      scroll.on('call', (value) => {

        if (value == 'startCounting') {

          $('.counting').each(function () {
            var $this = $(this),
              countTo = $this.attr('data-count');

            $({
              countNum: $this.text()
            }).animate({
                countNum: countTo
              },

              {

                duration: 3000,
                easing: 'linear',
                step: function () {
                  $this.text(Math.floor(this.countNum).toLocaleString('fr'));
                },
                complete: function () {
                  $this.text((this.countNum).toLocaleString('fr'));
                  //alert('finished');
                }

              });

          });
        }

        if (value == 'startCounting2') {

          $('.counting2').each(function () {
            var $this = $(this),
              countTo = $this.attr('data-count');

            $({
              countNum: $this.text()
            }).animate({
                countNum: countTo
              },

              {

                duration: 3000,
                easing: 'linear',
                step: function () {
                  $this.text(Math.floor(this.countNum).toLocaleString('fr'));
                },
                complete: function () {
                  $this.text((this.countNum).toLocaleString('fr'));
                  //alert('finished');
                }

              });

          });
        }

        if (value == 'startCounting3') {

          $('.counting3').each(function () {
            var $this = $(this),
              countTo = $this.attr('data-count');

            $({
              countNum: $this.text()
            }).animate({
                countNum: countTo
              },

              {

                duration: 3000,
                easing: 'linear',
                step: function () {
                  $this.text(Math.floor(this.countNum).toLocaleString('fr'));
                },
                complete: function () {
                  $this.text((this.countNum).toLocaleString('fr'));
                  //alert('finished');
                }

              });

          });
        }

        if (value == 'startCounting4') {

          $('.counting4').each(function () {
            var $this = $(this),
              countTo = $this.attr('data-count');

            $({
              countNum: $this.text()
            }).animate({
                countNum: countTo
              },

              {

                duration: 3000,
                easing: 'linear',
                step: function () {
                  $this.text(Math.floor(this.countNum).toLocaleString('fr'));
                },
                complete: function () {
                  $this.text((this.countNum).toLocaleString('fr'));
                  //alert('finished');
                }

              });

          });
        }

        if (value == 'startCounting5') {

          $('.counting5').each(function () {
            var $this = $(this),
              countTo = $this.attr('data-count');

            $({
              countNum: $this.text()
            }).animate({
                countNum: countTo
              },

              {

                duration: 3000,
                easing: 'linear',
                step: function () {
                  $this.text(Math.floor(this.countNum).toLocaleString('fr'));
                },
                complete: function () {
                  $this.text((this.countNum).toLocaleString('fr'));
                  //alert('finished');
                }

              });

          });
        }

        if (value == 'startCounting6') {

          $('.counting6').each(function () {
            var $this = $(this),
              countTo = $this.attr('data-count');

            $({
              countNum: $this.text()
            }).animate({
                countNum: countTo
              },

              {

                duration: 3000,
                easing: 'linear',
                step: function () {
                  $this.text(Math.floor(this.countNum).toLocaleString('fr'));
                },
                complete: function () {
                  $this.text((this.countNum).toLocaleString('fr'));
                  //alert('finished');
                }

              });

          });
        }

        if (value == 'startCounting7') {

          $('.counting7').each(function () {
            var $this = $(this),
              countTo = $this.attr('data-count');

            $({
              countNum: $this.text()
            }).animate({
                countNum: countTo
              },

              {

                duration: 3000,
                easing: 'linear',
                step: function () {
                  $this.text(Math.floor(this.countNum).toLocaleString('fr'));
                },
                complete: function () {
                  $this.text((this.countNum).toLocaleString('fr'));
                  //alert('finished');
                }

              });

          });
        }

        if (value == 'startCounting8') {

          $('.counting8').each(function () {
            var $this = $(this),
              countTo = $this.attr('data-count');

            $({
              countNum: $this.text()
            }).animate({
                countNum: countTo
              },

              {

                duration: 3000,
                easing: 'linear',
                step: function () {
                  $this.text(Math.floor(this.countNum).toLocaleString('fr'));
                },
                complete: function () {
                  $this.text((this.countNum).toLocaleString('fr'));
                  //alert('finished');
                }

              });

          });
        }


        // ————————
      });



      $('.swiper-container2').mouseenter(function () {
        if ($(window).width() > 1024) {
          scroll.stop();
          scroll.scrollTo('#slider', -80);
        }

      });

      $('.swiper-container2').mouseleave(function () {
        scroll.start();
      });

      mySwiper.on('reachEnd', function () {
        setTimeout(
          function () {
            scroll.start();
          }, 320);

      });

      mySwiper.on('reachBeginning', function () {
        setTimeout(
          function () {
            scroll.start();
          }, 320);

      });

      //

      mySwiper2.on('reachEnd', function () {
        setTimeout(
          function () {
            scroll.start();
          }, 320);

      });

      mySwiper2.on('reachBeginning', function () {
        setTimeout(
          function () {
            scroll.start();
          }, 320);

      });

    });

}, 1000);

$('.c-header-logo a').click(function () {
  $('li a').removeClass('active');
});

$('li a').click(function () {
  $('li a').removeClass('active');
  $(this).addClass('active');

});

$('.c-header-toggler').click(function () {
  $(this).toggleClass('open');
  $('.c-menu').toggleClass('open');
  if ($(this).hasClass('open')) {
    togglerTl.play();
    menuTl.play();
  } else {
    togglerTl.reverse();
    menuTl.reverse();
  }
});

$('.c-menu-nav-link').click(function () {
  $('.c-menu-nav-link').removeClass('active');
  $(this).addClass('active');
  $('.c-menu, .c-header-toggler').removeClass('open');
  togglerTl.reverse();
  menuTl.reverse();
});

$('.c-header-logo a').click(function () {
  $('.c-menu, .c-header-toggler').removeClass('open');
  togglerTl.reverse();
  menuTl.reverse();
});


var togglerTl = gsap.timeline({
  paused: true
});

togglerTl
  .to(".c-header-toggler .c-header-toggler-line:nth-child(1)", {
    top: '50%',
    y: '-50%',
    duration: 0.32
  })
  .to(".c-header-toggler .c-header-toggler-line:nth-child(3)", {
    bottom: '50%',
    y: '50%',
    duration: 0.32
  }, 0)
  .set(".c-header-toggler .c-header-toggler-line:nth-child(2)", {
    autoAlpha: 0
  })
  .add('x')
  .to(".c-header-toggler .c-header-toggler-line:nth-child(1)", {
    rotate: '45deg',
    duration: 0.32,
    ease: "power4.out"
  })
  .to(".c-header-toggler .c-header-toggler-line:nth-child(3)", {
    rotate: '-45deg',
    duration: 0.32,
    ease: "power4.out"
  }, 'x')
  .to(".c-header-toggler", {
    rotate: '-90deg',
    duration: 0.32,
    ease: "power4.out"
  }, 'x');

var menuTl = gsap.timeline({
  paused: true
});

menuTl
  .fromTo(".c-menu", {
    y: '-100%'
  }, {
    y: '0',
    duration: 0.64,
    ease: "expoScale(0.5, 3, 'power2.inOut')"
  })
  .add('nav')
  .fromTo(".c-menu-nav-line-top, .c-menu-nav-line-bottom", {
    autoAlpha: 0,
    x: '-50%'
  }, {
    autoAlpha: 1,
    x: '0',
    duration: 0.64,
    ease: "expoScale(0.5, 3, 'power2.inOut')",
    stagger: 0.08
  }, '-=0.16')
  .fromTo(".c-menu-nav-link", {
    autoAlpha: 0,
    x: '-25%'
  }, {
    autoAlpha: 1,
    x: '0',
    duration: 0.48,
    ease: "expoScale(0.5, 3, 'power2.inOut')",
    stagger: 0.08
  }, 'nav')
  .fromTo(".c-menu-nav-number", {
    autoAlpha: 0,
    rotate: '45deg'
  }, {
    autoAlpha: 1,
    rotate: '90deg',
    duration: 0.64,
    ease: "expoScale(0.5, 3, 'power2.inOut')",
    stagger: 0.08
  }, 'nav+=0.16')
  .fromTo(".c-menu-infos", {
    autoAlpha: 0,
    y: '25%'
  }, {
    autoAlpha: 1,
    y: '0',
    duration: 0.64,
    ease: "expoScale(0.5, 3, 'power2.inOut')"
  }, 'nav+=0.24');

//Menu links hover

$(".c-menu-nav-item").each(function () {

  var menuLinksHoverTl = gsap.timeline({
    paused: true
  });

  menuLinksHoverTl
    .fromTo($(this).find('.c-menu-nav-hover'), {
      right: '100%'
    }, {
      right: '0%',
      duration: 0.32,
      ease: "expoScale(0.5, 3, 'power2.inOut')"
    })
    .fromTo($(this).find('.c-menu-nav-number, .c-menu-nav-link'), {
      color: '#1D174F',
      x: '0'
    }, {
      color: '#ffffff',
      x: '-16px',
      duration: 0.48,
      ease: "expoScale(0.5, 3, 'power2.inOut')"
    }, 0);

  $(this).mouseenter(function () {
    if ($('.c-menu').hasClass('open')) {
      menuLinksHoverTl.play();
    }
  });

  $(this).mouseleave(function () {
    if ($('.c-menu').hasClass('open')) {
      menuLinksHoverTl.reverse();
    }
  });

  $(this).on("click", function () {
    setTimeout(
      function () {
        menuLinksHoverTl.reverse();
      },
      1500);
  });

});

//DL Button

// var dlButtonHoverTl = gsap.timeline({paused: true});

// dlButtonHoverTl
// .to(".c-dl-button svg", {scale: 0.85, duration: 0.24})

// ;

// $('.c-dl-button').mouseenter(function () {
//   dlButtonHoverTl.play();
// });

// $('.c-dl-button').mouseleave(function () {
//   dlButtonHoverTl.reverse();
// }
// ).mouseleave();

$(window).scroll(function () {
  if ($(this).scrollTop() >= 100) {
    // console.log('test');
    $('.c-header').addClass('resize');
  } else {
    $('.c-header').removeClass('resize');
  }
});



console.log('Cinetic Communication Boilerplate');